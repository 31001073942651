import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Whisper, IconButton, Popover, Dropdown } from "rsuite";
import { Icon } from "@rsuite/icons";
import { FaEllipsisVertical } from "react-icons/fa6";
import { FaRegEdit } from "react-icons/fa";
import { MdOutlineArchive, MdOutlineUnarchive } from "react-icons/md";
import { CiPaperplane } from "react-icons/ci";

import { useAutomation } from './automationSlice';
import { useDopeUI } from "../ui/dopeUISlice"
import { timestampToDateTimeStr } from "../utils/date";
import DopeTabs from "../ui/DopeTabs";
import DopeSelect from "../ui/DopeSelect";
import { TagList } from "../ui/DopeTags";
import DopeButton from "../ui/DopeButton";
import AttributeDisplay from "../ui/AttributeDisplay";
import { AudienceItem } from "../campaigns/CampaignAudienceSummary";
import { FiltersSummary } from "../campaigns/CampaignFiltersSummary";
import AutomationDispatchTemplates from "./AutomationDispatchTemplates";
import AutomationPurchaseSummary from "./AutomationPurchaseSummary";
import SubscriptionSummary from "../integrations/Subscriptions/SubscriptionSummary";
import AutomationRunTable from "./AutomationRunTable";
import RecentActivity from "../overview/RecentActivity";
import AutomationMailPieceTable from "./AutomationMailPieceTable";
import AutomationManualTrigger from "./AutomationManualTrigger";

import './Automations.scss';


const modeOptions = [
  { value: 'manual', label: <div className="vertical-align row"><div className="mode-circle pending margin-8-r" /> Validation Mode</div> },
  { value: 'automatic', label: <div className="vertical-align flex row"><div className="mode-circle active margin-8-r" /> Active</div> },
  { value: 'reject', label: <div className="vertical-align flex row"><div className="mode-circle paused margin-8-r" /> Paused</div> },
];

const ArchiveAutomationModal = ({ showModal, setShowModal, archiveAutomation }) => { // TODO is this dead?
  return (
    <Modal open={showModal}>
      <div className="margin-bottom">Are you sure you want to archive this automation?</div>
      <div className="flex row space-between pad-t">
        <DopeButton
          props={{
            label: "Cancel",
            buttonClass: "outlined-black thin",
            onClick: () => setShowModal(false),
          }}
        />
        <DopeButton
          props={{
            label: "Archive",
            buttonClass: "filled-black thin",
            onClick: archiveAutomation,
          }}
        />
      </div>
    </Modal>
  );
}

const AutomationShow = () => {
  const navigate = useNavigate();
  const { automation, derivedAutomationAttrs, actions, isSaving } = useAutomation();
  const [dopeUI, dopeUIActions] = useDopeUI();
  const [showModal, setShowModal] = useState(false);
  const [triggerDrawerOpen, setTriggerDrawerOpen] = useState(false);

  const excludeList = derivedAutomationAttrs.excludeItems.map(item => <AudienceItem isExclusion={true} item={item} key={item.key} />);

  const attributes = (
    <div className="split-cols">
      <div>
        <AttributeDisplay label="Automation Type" value="Marketing Automation" />
        <AttributeDisplay label="Description" value={automation.description} />
        <AttributeDisplay label="Sending Type" value={derivedAutomationAttrs.sendingTypeDisplay} />
        <AttributeDisplay label="# Recipients" value={derivedAutomationAttrs.audienceSizeDisplay} />
        <AttributeDisplay label="Filters" value={<FiltersSummary filters={automation.campaign_template.list_generation_setting.data_axle_filters} />} />
      </div>
      <div>
        <AttributeDisplay label="Excluding" value={excludeList} />
        <AttributeDisplay label="Automation Throttle Max" value={derivedAutomationAttrs.throttleDisplay} />
        <AttributeDisplay label="Tags" value={<TagList tags={derivedAutomationAttrs.tags} />} />
      </div>
    </div>
  );

  const details = (
    <div className="margin-top">
      <AutomationDispatchTemplates
        withContactCol={true}
        withHeader={true}
        editable={false}
      />
      <div className="page-break" />
      {derivedAutomationAttrs.isNativeTrigger && (<SubscriptionSummary subscriptionId={automation.subscription_id} />)}
      {attributes}
      <div className="page-break" />
      <AutomationPurchaseSummary />
    </div>
  );

  const automationActivityFilters = [
    {
      field: 'historyable_id',
      operator: '=',
      value: automation.id,
    },
    {
      field: 'historyable_type',
      operator: '=',
      value: 'Automation',
    },
  ];

  const archiveAutomation = () => {
    try {
      actions.save({ status: 'archived' });
      setShowModal(false);
    } catch (e) {
      dopeUIActions.addFlashMessage({ type: 'error', body: "Unable to archive automation", header: 'Error!'})
    }
  };

  const unarchiveAutomation = () => {
    try {
      actions.save({ status: 'active' });
    } catch (e) {
      dopeUIActions.addFlashMessage({ type: 'error', body: "Unable to unarchive automation", header: 'Error!'})
    }
  };

  const canManuallyTrigger = automation.status === 'active' && automation.mode !== 'reject';

  const moreActions = ({ onClose, className }, ref) => {
    return (
      <Popover ref={ref} className={className} full>
        <Dropdown.Menu>
          <Dropdown.Item eventKey={1} onClick={() => navigate(`/automations/${automation.id}/details`)}>
            <div className="flex row vertical-align">
              <Icon as={FaRegEdit} size="18px" /><div className="margin-8-l">Edit Automation</div>
            </div>
          </Dropdown.Item>
          {automation.status === "archived" &&
            <Dropdown.Item eventKey={2} onClick={unarchiveAutomation}>
              <div className="flex row vertical-align">
                <Icon as={MdOutlineUnarchive} size="18px"/>
                <div className="margin-8-l">Unarchive Automation</div>
              </div>
            </Dropdown.Item>}
          {automation.status !== "archived" &&
            <Dropdown.Item eventKey={3} onClick={archiveAutomation}>
              <div className="flex row vertical-align">
                <Icon as={MdOutlineArchive} size="18px"/>
                <div className="margin-8-l">Archive Automation</div>
              </div>
            </Dropdown.Item>}
            <Dropdown.Item eventKey={4} onClick={() => setTriggerDrawerOpen(true)} disabled={!canManuallyTrigger}>
              <div className="flex row vertical-align">
                <Icon as={CiPaperplane} size="18px"/>
                <div className="margin-8-l">Manually Trigger</div>
              </div>
            </Dropdown.Item>
        </Dropdown.Menu>
      </Popover>
    );
  };

  return (
    <div className='flex-1'>

      <div className='link margin' onClick={() => navigate('/automations')}>← Back to all Automations</div>

      <div className="page">
        <div className="header-3 vertical-align row">{automation.name} {automation.status === "archived" &&
          <span className="archived-text margin-8-l">Archived</span>}
        </div>
        <div className="help-text margin-40-b">Created {timestampToDateTimeStr(automation.created_at)}</div>

        <div className="top-right-actions">
          <DopeSelect
            name="mode"
            value={automation.mode}
            onChange={actions.updateMode}
            options={modeOptions}
            disabled={isSaving || automation.status === 'archived'}
            disabledItemValues={derivedAutomationAttrs.noDispatches ? ['automatic'] : []}
            loading={isSaving}
            searchable={false}
            style={{ width: 200 }}
          />

          <Whisper placement="bottomEnd" trigger="click" speaker={moreActions}>
            <IconButton className="border" appearance="subtle" icon={<Icon as={FaEllipsisVertical} />} />
          </Whisper>
        </div>

        <DopeTabs>
          <DopeTabs.Tab name="details" label="Automation Details">
            {details}
          </DopeTabs.Tab>

          <DopeTabs.Tab name="triggers" label="Trigger History">
            <AutomationRunTable />
          </DopeTabs.Tab>

          <DopeTabs.Tab name="mailing" label="Mailing List">
            <AutomationMailPieceTable />
          </DopeTabs.Tab>

          <DopeTabs.Tab name="activity" label="Change Log">
            <RecentActivity
              style={{ padding: 0, paddingTop: 20 }}
              withHeader={false}
              withPagination={true}
              withDetails={true}
              withFilters={{ user: true, order: true, expand: true }}
              initialPerPage={10}
              initialFilters={automationActivityFilters}
            />
          </DopeTabs.Tab>
        </DopeTabs>
      </div>

      <ArchiveAutomationModal showModal={showModal} archiveAutomation={archiveAutomation} setShowModal={setShowModal} />
      <AutomationManualTrigger open={triggerDrawerOpen} onClose={() => setTriggerDrawerOpen(false)} />
    </div>
  );
};

export default AutomationShow;
