import React, { useEffect } from "react";
import { SelectPicker } from "rsuite";
import './Subscriptions.scss';

const planClass = {
  "LIL' TASTE": "lil-taste",
  "DOPE": "dope",
  "DOPER": "doper",
  "DOPEST": "dopest",
  "FREEBIE": "freebie",
}

const SubscriptionItem = ({subscription}) => {

  return (
    <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", padding: "20px"}}>
      <div className={`subscription-logo ${planClass[subscription.nickname]}`}>
        {subscription.nickname}
      </div>
      <div className="selector-price-interval">
        ${subscription.unit_amount / 100}/{subscription.recurring.interval}
      </div>
    </div>
  )
}

const SubscriptionSelectDropdown = ({ selectedSubscription, subscriptionOptions, handleSelectChange }) => {
  useEffect(() => {
    const defaultSubscription = subscriptionOptions.find(sub => sub.nickname === "DOPE" && sub.recurring.interval === "year");
    if (defaultSubscription) {
      handleSelectChange(defaultSubscription.id);
    }
  }, [subscriptionOptions])

  return (
    <>
      <h5 className="subscription-subheader">Your Subscription</h5>
      <SelectPicker
        data={subscriptionOptions}
        onChange={handleSelectChange}
        searchable={false}
        placeholder={"Select a plan"}
        value={selectedSubscription}
        cleanable={false}
        style={{width: "481px"}}
        renderValue={(value, item) => {
          if (item) {
            return <SubscriptionItem subscription={item} />;
          }
          return null;
        }}
        renderMenuItem={(label, item) => (
          <SubscriptionItem subscription={item}/>
        )}
      />
    </>
  )
}

export default SubscriptionSelectDropdown;
