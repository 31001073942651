import { useEffect, useRef, useState } from "react";

import DopeButton from "../ui/DopeButton";
import { DopeFormGroup } from "../ui/DopeForm";
import DopeLoader from "../ui/DopeLoader";
import DopeSelect from "../ui/DopeSelect";
import { useTrackingNumber } from "./trackingNumberSlice";
import DopeApi from "../services/DopeApi";
import "./TrackingNumbers.scss";
import StripeDefaultCardDisplay from "../stripe/StripeDefaultCardDisplay";
import authApi from "../auth/authApi";
import { useNavigate } from "react-router-dom";
import { Icon, Close } from "@rsuite/icons";
import { MdOutlineArrowDropDown, MdOutlineClear, MdOutlineSearch } from "react-icons/md";
import { DateRangePicker, Dropdown, Checkbox, InputGroup, Input, IconButton } from "rsuite";

const footerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '2rem',
};

const api = new DopeApi("tracking_numbers");


const nameInput = { inputType: "text", name: "name", props: { label: "Tracking Number Name", placeholder: "Tracking Number Name" } };

const forwardNumberInput = { inputType: "phone", name: "forward_to", props: { label: "What number should it forward to?", placeholder: "+16513098577" } };



const numberInput = { inputType: "text", name: "number", props: { label: "Select a number", placeholder: "Select...", disabled: true } };

const DopeSearchInput = ({ value, onChange, loading, disabled }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", marginLeft: "auto" }}>
      <InputGroup size="md" style={{ width: "100%" }}>

        <Input onChange={value => onChange(value)} value={value} placeholder="Search" maxLength={3} disabled={disabled === true} />

        {value && value.length > 0 && !loading && (
          <InputGroup.Addon onClick={() => onChange('')} className="clickable">
            <Icon as={MdOutlineClear} />
          </InputGroup.Addon>
        )}

        {loading && (
          <InputGroup.Addon>
            <DopeLoader size="sm" />
            </InputGroup.Addon>
        )}

        {(!value || value.length === 0) && (
          <InputGroup.Addon>
            <Icon as={MdOutlineSearch} />
          </InputGroup.Addon>
        )}

      </InputGroup>
    </div>
  );
};

const TrackingNumberDrawer = ({ id = "new", onClose, reload }) => {
  const navigate = useNavigate();
  const prevTrackingNumberId = useRef(id);
  const prevSavingState = useRef(false);

  const [loading, setLoading] = useState(false);
  const [availableNumbers, setAvailableNumbers] = useState(null);

  const { trackingNumber, actions, isSaving } = useTrackingNumber(id);

  useEffect(() => {

    return () => {
      actions.resetToInitial();
    };
  }, []);

  useEffect(() => {
    const prevId = prevTrackingNumberId.current;

    if (trackingNumber && trackingNumber.id !== 'new' && prevId === 'new') {
      setLoading(false);
      onClose(true);
    }

  }, [trackingNumber?.id]);


  useEffect(() => {
    const prevSaving = prevSavingState.current;

    if (prevSaving && !isSaving) {
      if (trackingNumber.id !== 'new') {
        onClose(true);
      }
    }

    prevSavingState.current = isSaving;
  }, [isSaving]);

  if (!trackingNumber) {
    return <DopeLoader />;
  }

  const searchNumber = async (area_code) => {
    setLoading(true);

    try {
      const numbers = await api.get('search_numbers', { area_code });

      setAvailableNumbers(numbers);
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  };

  const handleClickUpdateCard = () => {
    navigate(`/accounts/${authApi.getCurrentAccountId()}/billing`);
  };

  const inputProps = { values: trackingNumber, onChange: actions.update, errors: trackingNumber.errors };

  const canSubmit = () => {
    return trackingNumber.name && trackingNumber.name.trim() !== '' && trackingNumber.number && trackingNumber.forward_to && trackingNumber.forward_to.length >= 10 && trackingNumber.area_code;
  };

  const areaCodeInput = { inputType: "text", name: "area_code", props: { label: "What area code do you want for your number?", placeholder: "Search an area code...", disabled: loading || isSaving || id !== 'new', maxLength: 3 } };

  return (
    <div className="tracking-number-drawer">
      <div>
        <div>
          <DopeFormGroup input={nameInput} {...inputProps} />
        </div>
        <div>
          <DopeFormGroup input={forwardNumberInput} {...inputProps} />
        </div>
        <div style={{ marginTop: '1rem' }}>
          <label className="label rs-form-control-label">What area code do you want for your number?</label>
          <DopeSearchInput
            value={trackingNumber.area_code}
            onChange={(value) => {
              if (value === '') {
                actions.update({ area_code: value });
                setAvailableNumbers(null);
                return;
              }
              actions.update({ area_code: value });

              if (value.length === 3) {
                searchNumber(value);
              }
            }}
            loading={loading}
            disabled={loading || isSaving || id !== 'new'}
          />
        </div>
        {availableNumbers && availableNumbers.length === 0 && (
          <div className="rs-form-group">
            <p className="rs-form-control-label" style={{color: 'red'}}>No numbers found for this area code</p>
          </div>
        )}
        {availableNumbers && availableNumbers.length > 0 && (
          <div className="rs-form-group">
            <label className="label rs-form-control-label">Select a number</label>
            <DopeSelect
              name="number"
              options={availableNumbers.map((number) => ({ label: number.friendly_name, value: number.phone_number }))}
              value={trackingNumber.number}
              onChange={actions.update}
              error={trackingNumber.errors?.number}
              disabled={availableNumbers.length === 0 || loading || id !== 'new'}
            />
          </div>
        )}

        {id === 'new' && (
          <div className="rs-form-group">
            <StripeDefaultCardDisplay onClickUpdate={handleClickUpdateCard} />
          </div>
        )}

        {id !== 'new' && (
          <DopeFormGroup input={numberInput} {...inputProps} />
        )}
      </div>
      <div style={footerStyle}>
          <DopeButton
            disabled={loading || isSaving}
            props={{
              onClick: onClose,
              buttonClass: 'outlined-red',
              disabled: isSaving,
              label: 'Cancel',
              loading: isSaving,
            }}
          />

          <DopeButton
            disabled={loading || !canSubmit() || isSaving}
            props={{
              onClick: () => {
                actions.save();
              },
              disabled: isSaving || loading,
              buttonClass: 'filled',
              label: id === 'new' ? 'Save Number' : 'Save & Update',
              loading: isSaving,
            }}
          />
        </div>
    </div>
  );

};

export default TrackingNumberDrawer;
