import React from "react";

import { Link } from "react-router-dom";
import { FaEdit, FaTrash } from "react-icons/fa";

import { useSweetSpotAnalysisUI } from "./sweetSpotAnalysisUISlice";

const styles = {
  cursor: "pointer",
  fontWeight: 600,
  fontSize: "13px",
  lineHeight: "20px",
  color: "#292B2E",
  marginBottom: "10px",
  display: "flex",
  alignItems: "center",
};

const iconStyles = {
  marginRight: "7px",
  color: "#8793A6",
  fontSize: "18px",
};

const SweetSpotAnalysisDropdown = ({ rowData: currentTrackingNumber, onClose }) => {
  let { id } = currentTrackingNumber;

  const [sweetSpotAnalysisUI, sweetSpotAnalysisUIActions] = useSweetSpotAnalysisUI();

  return (
    <>
      <div>
        <Link to={`/sweet_spot_analyses/${id}/setup`} style={styles}><FaEdit style={iconStyles} />Edit Analysis</Link>
        <p onClick={() => sweetSpotAnalysisUIActions.setDeleteSweetSpotAnalysisId(id)} style={styles}><FaTrash style={iconStyles} />Delete Report</p>
      </div>
    </>
  )
}

export default SweetSpotAnalysisDropdown;
