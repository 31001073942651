import React, { useEffect, useRef, useState } from "react";

import GoogleMapReact from "google-map-react";

import { useSweetSpotAnalysis } from "./sweetSpotAnalysisSlice";
import DopeTable from "../ui/DopeTable";
import useSupercluster from "use-supercluster";

import { FaMapMarkerAlt } from "react-icons/fa";
import ArrowRightLineIcon from "@rsuite/icons/ArrowRightLine";
import DopeDrawer from "../ui/DopeDrawer";

import './SweetSpotAnalysis.scss';

const columns = [
  { dataKey: 'zip', label: 'Zip Code', type: 'text', sortable: true },
  { dataKey: 'contacts_count', label: 'Amount', type: 'text', sortable: true },
  { dataKey: 'average_value', label: 'Avg Value', type: 'currency', sortable: true },
  { dataKey: 'total_value', label: 'Total Value', type: 'currency', sortable: true },
  { type: "icon", dataKey: "zip", icon: <ArrowRightLineIcon />, width: 50 },
];

const getContactType = (contact) => {
  if (contact.is_client) {
    return "Client";
  }

  if (contact.is_lead) {
    return "Lead";
  }

  if (contact.is_prospect) {
    return "Prospect";
  }

  return '';
};

const contactColumns = [
  { dataKey: 'full_name', label: 'Full Name', type: 'text' },
  { dataKey: 'full_address', label: 'Address', type: 'address_text' },
  { data_key: 'full_name', label: 'Contact Type', type: 'text', render: getContactType },
  { dataKey: 'client_value', label: 'Value', type: 'currency' },
];

const messageStyles = {
  backgroundColor: '#f8fbfc',
  padding: '1rem',
  borderRadius: '4px',
  marginBottom: '2rem',
  color: '#6c757d',
};

const Marker = ({ children }) => children;

const SweetSpotAnalysisReview = ({ viewOnly = false }) => {
  const { sweetSpotAnalysis, actions } = useSweetSpotAnalysis();

  const mapRef = useRef(null);
  const mapsRef = useRef(null);
  const listIdRef = useRef(null);

  const [markers, setMarkers] = useState([]);
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(4);
  const [center, setCenter] = useState({ lat: 39.8283, lng: -98.5795 });

  const [sortColumn, setSortColumn] = useState('zip');
  const [sortDirection, setSortDirection] = useState('asc');

  const { clusters, supercluster } = useSupercluster({
    points: markers,
    bounds,
    zoom: zoom,
    options: { radius: 75, maxZoom: 20 }
  });

  const [zip, setZip] = useState(null);

  useEffect(() => {
    if (sweetSpotAnalysis.id === 'new') {
      actions.preview();
    }
  }, [sweetSpotAnalysis.id]);

  // Call the preview if the list_id changes
  useEffect(() => {
    if (listIdRef.current !== sweetSpotAnalysis.list_id) {
      actions.preview();
      listIdRef.current = sweetSpotAnalysis.list_id;
    }
  }, [sweetSpotAnalysis.list_id]);


  useEffect(() => {
    if (sweetSpotAnalysis) {
      actions.sort({ sortColumn, sortDirection });
    }
  }, [sortColumn, sortDirection]);

  useEffect(() => {
    if (sweetSpotAnalysis && sweetSpotAnalysis.status === 'complete' && sweetSpotAnalysis.report_data) {
      const contacts = sweetSpotAnalysis.report_data.flatMap(data => data.contacts);

      const points = [];

      contacts.forEach(contact => {
        let { latitude, longitude } = contact;

        if (latitude && longitude) {
          points.push({
            type: "Feature",
            properties: { cluster: false, contactId: contact.id, category: contact.zip },
            geometry: {
              type: "Point",
              coordinates: [
                parseFloat(longitude),
              parseFloat(latitude)
              ]
            }
          });
        }
      });

      points.sort((a, b) => a.properties.zip - b.properties.zip);

      setMarkers(points);

      if (points.length > 0) {
        const latitudes = points.map(marker => marker.geometry.coordinates[1]);
        const longitudes = points.map(marker => marker.geometry.coordinates[0]);

        const centerLatitude = latitudes.reduce((sum, lat) => sum + lat, 0) / latitudes.length;
        const centerLongitude = longitudes.reduce((sum, lon) => sum + lon, 0) / longitudes.length;

        setCenter({
          lat: centerLatitude,
          lng: centerLongitude
        });
        setZoom(8);
      }

    }
  }, [sweetSpotAnalysis?.list_id, sweetSpotAnalysis?.status]);

  const isNew = sweetSpotAnalysis.id === 'new' || !sweetSpotAnalysis.id;

  return (
    <div>
      <div className="page-header">
        <p className="header-text">{sweetSpotAnalysis.name}{viewOnly ? '' : ' Review'}</p>
      </div>
      {sweetSpotAnalysis.status === 'complete' && (
        <div style={{ width: '100%', height: '500px', marginBottom: '1rem' }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
              libraries: ['drawing', 'places'],
            }}
            center={center}
            zoom={zoom}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => {
              mapRef.current = map;
              mapsRef.current = maps;
            }}
            onChange={({ zoom, bounds }) => {
              setZoom(zoom);
              setBounds([
                bounds.nw.lng,
                bounds.se.lat,
                bounds.se.lng,
                bounds.nw.lat
              ]);
            }}
            options={{
              streetViewControl: false,
              mapTypeControl: true,
              fullscreenControl: false,
            }}
          >
            {clusters.map(cluster => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const {
              cluster: isCluster,
              point_count: pointCount
            } = cluster.properties;

            if (isCluster) {
              return (
                <Marker
                  key={`cluster-${cluster.id}`}
                  lat={latitude}
                  lng={longitude}

                >
                  <div
                    className="cluster-marker"
                    style={{
                      width: `${10 + (pointCount / markers.length) * 20}px`,
                      height: `${10 + (pointCount / markers.length) * 20}px`
                    }}
                    onClick={() => {
                      const expansionZoom = Math.min(
                        supercluster.getClusterExpansionZoom(cluster.id),
                        20
                      );

                      mapRef.current.setZoom(expansionZoom);
                      mapRef.current.panTo({ lat: latitude, lng: longitude });
                    }}
                  >
                    {pointCount}
                  </div>
                </Marker>
              );
            }

            return (
              <Marker
                key={cluster.properties.contactId}
                lat={latitude}
                lng={longitude}
              >
                <FaMapMarkerAlt fill="#EA0029" />
              </Marker>
            );
          })}
          </GoogleMapReact>
        </div>
      )}

      {!isNew && (sweetSpotAnalysis.status === 'pending' || sweetSpotAnalysis.status === 'processing') && (
         <div style={messageStyles}>
          <p><b>Sit tight - your report is being generated!</b></p>
          <p>Once your report has completed, you'll be able to view your data as a map.</p>
        </div>
      )}

      <DopeTable
        data={sweetSpotAnalysis.report_data || []}
        columns={columns}
        loading={!sweetSpotAnalysis.report_data || sweetSpotAnalysis.isGetting}
        onRowClick={(rowData) => setZip(rowData)}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        onSortColumn={(sortColumn, sortDirection) => {
          setSortColumn(sortColumn);
          setSortDirection(sortDirection);
        }}
        onClickZip={(zip) => {}}
        />

      <DopeDrawer
        size="lg"
        header={`Zip Code ${zip?.zip}`}
        subheader={`(${zip?.contacts?.length ?? 0}) Contacts`}
        open={!!zip}
        onClose={() => setZip(null)}
        bodyContent={
          <div>
            <DopeTable
              data={zip?.contacts || []}
              columns={contactColumns}
            />
          </div>
        }
      />
    </div>
  );
};

export default SweetSpotAnalysisReview;
