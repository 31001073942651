import React, { useEffect } from 'react';
import { useOutletContext } from "react-router-dom";
import { MdRoofing, MdApartment, MdMapsHomeWork } from 'react-icons/md';

import { useCampaign, useCampaignListGenerationSettings } from '../campaigns/campaignSlice';

import { DopeFormGroup } from '../ui/DopeForm';
import DopePlayer from '../ui/DopePlayer';

const videoUrl = process.env.REACT_APP_CAMPAIGN_SETUP_VIDEO_URL;

const nameInput = { inputType: 'text', name: 'name', props: { label: 'Campaign Name' } };
const tagsInput = { inputType: 'tags', name: 'taggings', props: { label: 'Tags', modelName: 'campaign' } };
const audienceTypeInput = { inputType: 'radio_tile', name: 'audience', props: {
  label: 'Audience Type',
  options: [
    { value: 'all', label: 'Business & Residential', icon: MdRoofing },
    { value: 'residential', label: 'Residential Only', icon: MdMapsHomeWork },
    { value: 'commercial', label: 'Business Only', icon: MdApartment },
  ],
}};

const EddmSetup = () => {
  const [campaign, campaignActions] = useCampaign();
  const { pageTabs } = useOutletContext();

  const campaignInputProps = { values: campaign, onChange: campaignActions.update, errors: campaign.errors };

  const handleAudienceTypeChange = (value) => {
    campaignActions.updateEDDM({ audience: value.audience});
  };

  return (
    <>
      <div className="page">
        {pageTabs}

        <div className="page-header">
          <p className="header-text">Define campaign details</p>
          <p className="help-text">Name your campaign and add tags to help organize your campaigns!</p>
        </div>

        <div className="rs-form split-cols">
          <div>
            <DopeFormGroup input={nameInput} {...campaignInputProps} />

            <DopeFormGroup
              input={audienceTypeInput}
              onChange={handleAudienceTypeChange}
              values={campaign.eddm}
            />

            <DopeFormGroup input={tagsInput} {...campaignInputProps} />
          </div>

          <div><DopePlayer url={videoUrl}/></div>
        </div>
      </div>
    </>
  );
}

export default EddmSetup;
