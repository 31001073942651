import React, { useEffect, useState } from 'react';
import { useOutletContext } from "react-router-dom";
import { Radio, RadioGroup, SelectPicker } from "rsuite";

import { useCampaign } from '../campaigns/campaignSlice';
import { useDopeUI } from "../ui/dopeUISlice";
import DopeTable from "../ui/DopeTable";
import EddmZipCodeInput from "./EddmZipCodeInput";
import EddmRouteMapInput from "./EddmRouteMapInput";
import EddmRoutesSummary from "./EddmRoutesSummary";

const IncomeCell = ({ rowData }) => {
  const medIncome = rowData.MED_INCOME;

  return (
    <div>
      {medIncome ? `$${medIncome.toLocaleString()}` : 'N/A'}
    </div>
  );
};

const ZipCridCell = ({ rowData }) => {
  return (
    <div>
      {rowData.ZIP_CODE}-{rowData.CRID_ID}
    </div>
  );
};

const columns = [
  { type: 'custom_component', label: 'Route', Component: ZipCridCell },
  { type: 'text', label: 'Residential', dataKey: 'RES_CNT' },
  { type: 'text', label: 'Business', dataKey: 'BUS_CNT' },
  { type: 'text', label: 'Total', dataKey: 'TOT_CNT' },
  { type: 'custom_component', label: 'Med. Income', Component: IncomeCell },
];

const audienceOptions = [
  { label: 'Business & Residential', value: 'all' },
  { label: 'Residential', value: 'residential' },
  { label: 'Business', value: 'commercial' },
];

const EddmRoutes = () => {
  const [campaign, campaignActions, options] = useCampaign();
  const [dopeUI, dopeUIActions] = useDopeUI();

  const [mapView, setMapView] = useState(true);

  const { eddm } = campaign;
  const { pageTabs } = useOutletContext();
  const { fetching, routes, routeErrors } = options.routesData;

  useEffect(() => {
    if (routeErrors) {
      dopeUIActions.addFlashMessage({ header: 'Could not fetch mail routes', body: 'Please try again later', type: 'error' });
    }
  }, [routeErrors]);

  const selected = (route) => {
    return eddm.zip_crids.includes(route.ZIP_CRID);
  };

  const rowClick = (row) => {
    if (!row.TOT_CNT || row.TOT_CNT === 0) {
      dopeUIActions.addFlashMessage({ header: 'Route has no deliverable addresses', body: 'Please select another route', type: 'warning' });
      return;
    }

    campaignActions.toggleEDDMRoute(row.ZIP_CRID);
  }

  return (
    <>
      <div className="page">
        {pageTabs}

        <div className="page-header">
          <p className="header-text">Select your Routes</p>
          <p className="help-text">Select enough routes to meet the 200 piece minimum required by the USPS</p>
        </div>

        <div className="flex row">
          <div className="flex-grow">
            <EddmZipCodeInput zipCode={eddm.zip_code} onChange={campaignActions.updateEDDMZipCodeData} loading={fetching} />

            <div className="margin-top">
              <div className="to-right square-border rounded-border-top" style={{ marginBottom: "-1px"}}>
                <RadioGroup inline value={mapView} onChange={value => setMapView(value)}>
                  <Radio value={true}>Map View</Radio>
                  <Radio value={false}>Table View</Radio>
                </RadioGroup>
              </div>
              <div className={fetching ? 'disabled' : ''}>
                {mapView ? <EddmRouteMapInput /> : (
                  <div style={{ marginTop: "-6px" }}>
                    <DopeTable
                      columns={columns}
                      data={routes.map(route => route.attributes)}
                      onRowClick={rowClick}
                      selected={selected}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="margin-left" style={{ width: 275 }}>
            <div className="margin-bottom">
              <label className="rs-form-control-label">Audience Type</label>
              <SelectPicker
                searchable={false}
                cleanable={false}
                style={{width: '100%'}}
                value={eddm.audience}
                onChange={(value) => campaignActions.updateEDDM({ audience: value })}
                data={audienceOptions}
              />
            </div>

            <EddmRoutesSummary />
          </div>

        </div>
      </div>
    </>
  );
}

export default EddmRoutes;
