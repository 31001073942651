import React from "react";

import { useCampaign, useCampaignListGenerationSettings } from "./campaignSlice";
import { useCampaignUI } from "./campaignUISlice";

import { FlexboxGrid, Tooltip, Whisper } from "rsuite";
import CheckIcon from '@rsuite/icons/Check';
import CloseIcon from '@rsuite/icons/Close';
import RemindRoundIcon from '@rsuite/icons/RemindRound';
import AttributeDisplay from "../ui/AttributeDisplay";
import { capitalize } from "lodash";
import CampaignFiltersSummary from "./CampaignFiltersSummary";
import { TagList } from "../ui/DopeTags";


const AudienceItem = ({ isExclusion, item }) => {
  const { type, name, count, status, status_message } = item;

  const countDisplay = count ? `(${count} Contacts)` : '';
  const isError = status === 'error';

  let marker = isExclusion ? <CloseIcon color="red" /> : <CheckIcon color="green" />;
  let typeDisplay = (<span>{marker}&nbsp;&nbsp;<b>{type}:</b></span>)
  if (isError) {
    marker = <RemindRoundIcon color="red" />;
    const errorMessage = status_message || 'Failed to generate list';
    typeDisplay = (
      <Whisper trigger="hover" placement="top" speaker={<Tooltip>{errorMessage}</Tooltip>}>
        <span className="color-text-red bold hoverable"><span>{marker}&nbsp;&nbsp;<b>{type}:</b></span></span>
      </Whisper>
    );
  }

  return (
    <div className="vertical-align flex-wrap">
      {typeDisplay}&nbsp;{name}&nbsp;{countDisplay}
    </div>
  );
};

const CampaignContactGroupList = ({ contactGroups, isExclusion }) => {
  const empty = contactGroups.length === 0;

  return (
    <div className="campaign-list-list">
      {contactGroups.map(item => (
        <div className="campaign-list-list-item" key={item.key}>
          <AudienceItem isExclusion={isExclusion} item={item} />
        </div>
      ))}
      {empty && <div className="campaign-list-list-item">None</div>}
    </div>
  );
};

const campaignTypeDisplay = {
  User: "User Generated Campaign",
  AutomationRun: "Automated Campaign",
};

const EddmCampaignSummary = () => {
  const [campaign, actions, options] = useCampaign();
  const { eddm } = campaign;
  const { audience } = eddm;
  const { eddmData, routesData } = options;

  return (
    <FlexboxGrid justify="space-between" className="rs-form flexbox-split">
      <FlexboxGrid.Item colspan={11} >
        <AttributeDisplay label="Campaign Type" value={campaignTypeDisplay[campaign.creator_type]} />
        <AttributeDisplay label="Sending Type" value={"EDDM"} />
        <AttributeDisplay label="Audience Type" value={capitalize(audience)} />
        <AttributeDisplay label="Tags" value={<TagList tags={campaign.taggings.map(t => t.tag)} />} />
      </FlexboxGrid.Item>

      <FlexboxGrid.Item colspan={11}>
        <AttributeDisplay label="Total Contacts" value={routesData.fetching ? '---' : eddmData.audienceCount.toLocaleString()} />
        <AttributeDisplay label="Routes" value={campaign.eddm.zip_crids.join(" ")} />
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
};

const CampaignListsSummary = () => {
  const [campaign] = useCampaign();
  const [settings] = useCampaignListGenerationSettings();
  const [campaignUI] = useCampaignUI();
  const { starting_list, generation_type, full_name, full_address } = settings;
  const { list_generations, list_generation_setting_seed_lists, list_generation_setting_suppression_lists } = settings;

  const seedLists = list_generation_setting_seed_lists.map(l => ({
    key: 'list-' + l.list.id,
    type: 'Seed List',
    count: l.list.contacts_count,
    name: l.list.name,
  }));

  const generationLists = list_generations.map(l => ({
    key: 'list-' + l.list.id,
    type: 'List Generation',
    count: l.list.contacts_count,
    name: l.list.name,
    status: l.status,
    status_message: l.status_message,
  }));

  const includingGroups = [...seedLists, ...generationLists];
  if (!campaign.scheduled && generation_type !== 'list') {
    const toGenerateList = {
      key: 'to-generate',
      type: 'List Generation',
      count: campaignUI.purchase_summary?.new_contacts_count || '---',
      name: 'Campaign Contact Generation Estimate',
    };
    includingGroups.push(toGenerateList);
  }

  if (generation_type == 'list') {
    if (starting_list) {
      includingGroups.push({
        key: 'starting-list-' + starting_list.id,
        type: 'Starting List',
        count: starting_list.contacts_count,
        name: starting_list.name,
      });
    } else { // direct send
      includingGroups.push({
        key: 'starting-contact',
        type: 'Contact',
        count: null,
        name: `${full_name} - ${full_address}`,
      });
    }
  }

  const suppressionLists = list_generation_setting_suppression_lists.map(l => ({
    key: 'list-' + l.list.id,
    type: 'List',
    count: l.list.contacts_count,
    name: l.list.name,
  }));

  const excludingGroups = [...suppressionLists];

  const tagList = (
    <div>
      <TagList tags={campaign.taggings.map(t => t.tag)} />
      {campaign.taggings.length === 0 && <div>None</div>}
    </div>
  );

  let sendingType = {
    list: "List Campaign",
    blitz: "Neighborhood Blitz",
    list_blitz: "List Blitz",
  }[generation_type];
  if (generation_type === 'list' && !starting_list) {
    sendingType = "Direct Sending";
  }

  return (
    <FlexboxGrid justify="space-between" className="rs-form flexbox-split">
      <FlexboxGrid.Item colspan={11} >
        <AttributeDisplay label="Campaign Type" value={campaignTypeDisplay[campaign.creator_type]} />
        <AttributeDisplay label="Sending Type" value={sendingType} />
        <AttributeDisplay label="Audience Type" value={capitalize(campaign.list_generation_setting.audience_type)} />
        <AttributeDisplay label="Filters" value={<CampaignFiltersSummary allowRemove={false} emptyText="None"/>}/>
        <AttributeDisplay label="Tags" value={tagList} />
      </FlexboxGrid.Item>

      <FlexboxGrid.Item colspan={11}>
        <AttributeDisplay label="Contacts" value={<CampaignContactGroupList contactGroups={includingGroups} isExclusion={false}/>}/>
        <AttributeDisplay label="Excluding" value={<CampaignContactGroupList contactGroups={excludingGroups} isExclusion={true}/>}/>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
};

const CampaignAudienceSummary = () => {
  const [campaign] = useCampaign();

  return (
    <div className="campaign-audience-summary">
      <div className="campaign-lists-summary">
        {campaign.is_eddm ? <EddmCampaignSummary /> : <CampaignListsSummary/>}
      </div>
    </div>
  );
};

export default CampaignAudienceSummary;

export {
  AudienceItem,
}
