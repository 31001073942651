import React, { useEffect, useState } from 'react';

import { useCampaign } from "../campaigns/campaignSlice";
import DopeButton from "../ui/DopeButton";
import Cents from '../ui/Cents';
import DopeApi from "../services/DopeApi";

const productApi = new DopeApi('product');

const EddmRoutesSummary = () => {
  const [eddmProducts, setEddmProducts] = useState([]);
  const [campaign, campaignActions, options] = useCampaign();
  const { eddm } = campaign;
  const { eddmData, routesData } = options;
  const { routes, fetching } = routesData;
  const allCrids = routes.map(route => route.attributes.ZIP_CRID);

  useEffect(() => {
    const fetchEddmProducts = async () => {
      const response = await productApi.query({ scopes: [{ name: 'eddm' }] });
      let products = response.products;

      products.forEach(product => {
        const tierDiscounts = product.product_pricing_tier_discounts.map(tier_discount => tier_discount.discount)
        product.discounted_price = product.default_unit_price - Math.min(...tierDiscounts)
      });

      const sortedProducts = products.sort((a, b) => a.discounted_price - b.discounted_price);
      setEddmProducts(sortedProducts);
    }

    fetchEddmProducts();
  }, []);

  const costsPerProduct = eddmProducts.map(product => {
    return (
      <div className="flex row space-between margin-8-b" key={product.id}>
        <div>{product.label}</div>
        <div><Cents>{product.discounted_price * eddmData.audienceCount}</Cents></div>
      </div>
    );
  });

  return (
    <div className="background-blue-grey-shade-light border-radius-8 pad">
      <h4 className="header-4 margin-bottom">Summary</h4>

      <div className="label margin-4-b">Routes Selected</div>
      <p className="margin-bottom">{eddm.zip_crids.length}</p>

      <div className="label margin-4-b">Total Delivery Addresses</div>
      <p className="margin-bottom">{eddmData.audienceCount.toLocaleString()}</p>

      <div className="label margin-4-b">Cost Per Sending by Mailer Size</div>
      {costsPerProduct}

      {eddm.zip_code && (
        <div className="flex row space-between margin-top">
          <DopeButton
            props={{
              label: "Select All",
              onClick: () => campaignActions.setEDDMRoutes(allCrids),
              buttonClass: "text-link",
            }}
            className="padding-none"
            disabled={fetching}
          />

          <DopeButton
            props={{
              label: "Clear All",
              onClick: campaignActions.clearEDDMRoutes,
              buttonClass: "text-link",
            }}
            className="padding-none"
            disabled={fetching}
          />
        </div>
      )}
    </div>
  );
}

export default EddmRoutesSummary;
