import React, { useState } from "react";
import DopeApi from "../services/DopeApi";
import { useQuery2 } from "../hooks/useQuery";
import ArrowRightLineIcon from "@rsuite/icons/ArrowRightLine";
import DopeListPage from "../ui/DopeListPage";
import {handleDateFilter, handleFilterChange} from "../utils/fn";
import DopeDrawer from "../ui/DopeDrawer";
import MailRoutePieceDrawer from "./MailRoutePieceDrawer";

const mailRoutePieceApi = new DopeApi("mail_route_pieces");
const campaignApi = new DopeApi("campaigns");

const columns = [
  { type: 'text', dataKey: 'id', label: 'ID', flexGrow: 1, sortable: true },
  { type: 'text', dataKey: 'zip_crid', label: 'Carrier Route', flexGrow: 2, sortable: true },
  { type: 'text', dataKey: 'total_delivery_addresses', label: 'Address Count', flexGrow: 2, sortable: true },
  { type: 'name_link', dataKey: 'campaign_name', label: 'Campaign Name', flexGrow: 3, sortable: true, getUrl: (mail_route_piece) => `/campaigns/${mail_route_piece.campaign_id}/show` },
  { type: 'text', dataKey: 'mail_template_size', label: 'Mailer Type', flexGrow: 2, sortable: true },
  { type: "text", dataKey: "dispatch_date", label: "Send Date", flexGrow: 2, sortable: true },
  { type: "pill", pillTypeKey: "effective_status", dataKey: "effective_status", label: "Status", flexGrow: 2 },
  { type: "icon", dataKey: "id", icon: <ArrowRightLineIcon />, width: 50 },
]

const statusOptions = [
  { value: "cancelled", label: "Cancelled" },
  { value: "scheduled", label: "Scheduled" },
  { value: "sent", label: "Sent" },
];


const MailRoutePieceHistory = () => {
  const [routePieceDrawerId, setRoutePieceDrawerId] = useState(null);
  const { tableProps, paginationProps, onSearchClear, onSearchInput, setFilters, filters, reload } = useQuery2({
    api: mailRoutePieceApi,
    initialSearchScope: "name",
  });

  const applyCampaignIdFilter = (campaigns) => {
    const filterObject = {
      filterField: "dispatches.campaign_id",
      operator: "in",
      filterCategory: "Campaign",
    }
    handleFilterChange(filters, setFilters, campaigns, filterObject)
  }

  const applyStatusFilter = (status) => {
    const filterObject = {
      filterField: "mail_route_pieces.status",
      operator: "in",
      filterCategory: "Status",
    }
    handleFilterChange(filters, setFilters, status, filterObject)
  }

  const applyDateRangeFilter = (startDate, endDate) => {
    handleDateFilter(filters, setFilters, "dispatches.date", startDate, endDate);
  }

  const filterInputs = [
    { title: "Campaign",
      getOptions: (name = "") => campaignApi.getOptions({
        valueKey: "id",
        labelKey: "name",
        search: name.length > 0 ? { text: name, column: "name" } : {},
      }),
      applyFilter: applyCampaignIdFilter,
      searchable: true
    },
    { title: "Status", getOptions: () => { return statusOptions }, applyFilter: applyStatusFilter },
    { title: "Date Range", type: "dateRange" , applyFilter: applyDateRangeFilter },
  ]

  return (
    <div>
      <DopeListPage
        columns={columns}
        tableProps={tableProps}
        paginationProps={paginationProps}
        onSearchChange={onSearchInput}
        onSearchClear={onSearchClear}
        setFilters={setFilters}
        filters={filters}
        filterInputs={filterInputs}
        reload={reload}
        onClickId={(rowData) => setRoutePieceDrawerId(rowData.id)}
      />

      <DopeDrawer
        open={!!routePieceDrawerId}
        onClose={() => setRoutePieceDrawerId(null)}
        bodyContent={<MailRoutePieceDrawer id={routePieceDrawerId} />}
      />
    </div>
  );
}

export default MailRoutePieceHistory;
