import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { MdRoofing, MdApartment, MdMapsHomeWork } from "react-icons/md";

import pick from "lodash/pick";
import { useCampaign, useCampaignListGenerationSettings } from "./campaignSlice";
import DopeApi from "../services/DopeApi";

import { DopeFormGroup } from "../ui/DopeForm";
import DopePlayer from "../ui/DopePlayer";
import ExtraMailRules from "./ExtraMailRules";

const listApi = new DopeApi("list");

const nameInput = { inputType: "text", name: "name", props: { label: "Campaign Name" } };
const tagsInput = { inputType: "tags", name: "taggings", props: { label: "Tags", modelName: "campaign" } };
const audienceTypeInput = { inputType: "radio_tile", name: "audience_type", props: {
  label: "Audience Type",
  options: [
    { value: "residential", label: "Single Family Homeowners", icon: MdRoofing },
    { value: "all_residential", label: "All Residents", icon: MdMapsHomeWork },
    { value: "commercial", label: "Businesses", icon: MdApartment },
  ],
}};
const videoSettings = {
  list: { videoUrl: "https://dopemail-assets.s3.amazonaws.com/Sending+a+List+Campaign+Tutorial.mp4" },
  list_blitz: { videoUrl: "https://dopemail-assets.s3.amazonaws.com/Sending+Campaigns+to+Surrounding+Neighbors+%F0%9F%93%AC.mp4" },
  blitz: { videoUrl: "https://dopemail-assets.s3.amazonaws.com/Sending+Targeted+Mail+Campaigns+Tutorial.mp4" },
};

const CampaignSetup = () => {
  const [loadingStartingContact, setLoadingStartingContact] = useState(false);
  const [campaign, campaignActions] = useCampaign();
  const [listGenerationSettings, listGenerationSettingsActions] = useCampaignListGenerationSettings();
  const { pageTabs } = useOutletContext();

  const campaignInputProps = { values: campaign, onChange: campaignActions.update, errors: campaign.errors };
  const listGenerationSettingsInputProps = { values: listGenerationSettings, onChange: listGenerationSettingsActions.update, errors: listGenerationSettings.errors };

  const isListBlitz = listGenerationSettings.generation_type === "list_blitz";
  const isListOnly = listGenerationSettings.generation_type === "list";
  const requiresStartingList = isListBlitz || isListOnly;

  const startingListInput = { inputType: "belongsTo", name: "starting_list_id", props: {
    label: "What list should we send to?",
    belongsToModelName: "list",
    placeholder: "Select a list",
    labelKey: "name",
    loading: loadingStartingContact,
  }};

  const handleAudienceTypeChange = (value) => {
    listGenerationSettingsActions.update({ audience_type: value.audience_type, data_axle_filters: {} });
  };

  const handleStartingListChange = async (update, list) => {
    setLoadingStartingContact(true);

    const listId = update.starting_list_id;
    const startingContact = await listApi.fetchMember(listId, "starting_contact");

    if (startingContact) {
      listGenerationSettingsActions.update({
        starting_list_id: listId,
        starting_list: list,
        ...pick(startingContact, 'first_name', 'last_name', 'full_address', 'country', 'state', 'city', 'zip', 'zip_4', 'address_1', 'address_2', 'latitude', 'longitude'),
        errors: {},
      });
    } else {
      listGenerationSettingsActions.update({
        errors: { starting_list_id: `Selected list does not have any contacts with complete information.` },
        starting_list_id: null,
      });
    }
    setLoadingStartingContact(false);
  };

  const listOnlyContent = isListOnly ? (
    <div className="page">
      <div className="page-header">
        <p className="header-text">Let's refine your audience</p>
        <p className="help-text">Make sure your mail is getting in the right hands by adding and removing people.</p>
      </div>

      <ExtraMailRules />
    </div>
  ) : null;

  return (
    <>
      <div className="page">
        {pageTabs}

        <div className="page-header">
          <p className="header-text">Define campaign details</p>
          <p className="help-text">Name your campaign and add tags to help organize your campaigns!</p>
        </div>

        <div className="rs-form split-cols">
          <div>
            <DopeFormGroup input={nameInput} {...campaignInputProps} />

            {requiresStartingList && (
              <DopeFormGroup
                input={startingListInput}
                {...listGenerationSettingsInputProps}
                onChange={handleStartingListChange}
              />
            )}

            {!isListOnly && (<DopeFormGroup input={audienceTypeInput} onChange={handleAudienceTypeChange} values={listGenerationSettings} errors={listGenerationSettings.errors} />)}

            <DopeFormGroup input={tagsInput} {...campaignInputProps} />
          </div>

          <DopePlayer url={videoSettings[listGenerationSettings.generation_type].videoUrl} />
        </div>
      </div>

      {listOnlyContent}
    </>
  );
};

export default CampaignSetup;
