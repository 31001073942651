import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { Icon } from "@rsuite/icons";

import DopeApi from "../services/DopeApi";
import authApi from "../auth/authApi";
import {Input, InputGroup, Pagination} from "rsuite";
import DesignGridItem from "./DesignGridItem";
import {MdOutlineClear, MdOutlineSearch} from "react-icons/md";
import {handleFilterChange} from "../utils/fn";
import DopeFilterDelegator from "../ui/dopeFilters/DopeFilterDelegator";

const designApi = new DopeApi("design");
const taggingsApi = new DopeApi("taggings");

const initialScopes = [];

const designStatusConfig = [
  { label: "Approved", value: "approved" },
  { label: "Needs Review", value: "needs_review" },
  { label: "In Design", value: "in_design" },
  { label: "Drafts", value: "draft" },
  { label: "Archived", value: "archived" },
  { label: "All Statuses", value: "all" },
]

const designTypes = [
  { label: "Postcard", value: "PostcardTemplate" },
  { label: "Handwritten", value: "HandwrittenCardTemplate" },
  { label: "Goodies", value: "GoodieTemplate" },
];

const designsByStatus = (status, statusCounts, selectedStatus, onStatusClick) => {
  const isSelected = selectedStatus === status.value;
  let statusCount = 0;
  if (statusCounts[status.value]) {
    statusCount = statusCounts[status.value];
  } else if (status.value === "all") {
    statusCount = Object.values(statusCounts).reduce((a, b) => a + b, 0);
  }

  const selectedStyles = isSelected ? { borderBottom: "3px solid #EA0029", borderRadius: "0 0 3px 3px"} : {};
  const divStyles = { ...selectedStyles, padding: "10px 4px", marginRight: "20px" };

  return (
    <div onClick={() => onStatusClick(status.value, isSelected)} style={divStyles} key={status.value} className="clickable">
      <div className="flex row">
        <div className={isSelected ? "header-6" : "header-6 non-bold"} style={{ marginBottom: "-4px" }}>{status.label} ({statusCount})</div>
      </div>
    </div>
  )
}

const cardStyles = {
  minWidth: "200px",
  padding: "8px",
  margin: "4px",
  borderRadius: "8px",
  border: "1px solid #EAECEF",
}

const designTypeCard = (designType, selected, setSelected) => {
  const isSelected = selected === designType.value;
  const divStyles = isSelected ? {...cardStyles,  border: "2px solid #255FDF", backgroundColor: "#255FDF1A", borderRadius: "5px"} : cardStyles;

  return (
    <div onClick={() => setSelected(designType.value, isSelected)} style={divStyles} key={designType.value} className="clickable">
      <div className="flex row">
        <div className="label large">{designType.label}</div>
      </div>
      <div className="help-text">{designType.size}</div>
    </div>
  )

}

const getStatusCounts = async (setStatusCounts) => {
  const response = await designApi.fetchMember(authApi.getCurrentAccountId(), "design_counts_by_status");
  setStatusCounts(response);
}

const paginationLocale = {
  total: 'Total: {0}'
};

const DesignGridPage = ({ useQuery }) => {
  const { tableProps, paginationProps, onSearchInput, onSearchClear, setScopes, scopes, filters, setFilters } = useQuery;
  const [searchText, setSearchText] = useState("");
  const [statusCounts, setStatusCounts] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const location = useLocation();
  const [searchParams] = useSearchParams();
  const requestDesignStatus = searchParams.get("status");

  useEffect(() => {
    getStatusCounts(setStatusCounts);

    if (location.state && location.state.applyUnapprovedScope) {
      setSelectedStatus("needs_review");
      setScopes([...initialScopes, {name: "needs_review"}]);
    } else {
      setSelectedStatus("approved");
      setScopes([...initialScopes, {name: "approved"}]);
    }

    if (requestDesignStatus && requestDesignStatus === "needs_review") {
      setSelectedStatus("needs_review");
      setScopes([...initialScopes, {name: "needs_review"}]);
    }
  }, []);

  const handleSearchInput = (value) => {
    setSearchText(value);
    onSearchInput(value);
  }

  const handleSearchClear = () => {
    setSearchText("");
    onSearchClear();
  }

  const onStatusClick = (status, isSelected) => {
    if (!isSelected) {
      if (status === "all") {
        const newScopes = scopes.filter(scope => !designStatusConfig.map(status => status.value).includes(scope.name));
        setScopes(newScopes);
        setSelectedStatus("all");
      } else {
        let oldScopes = scopes.filter(scope => !designStatusConfig.map(status => status.value).includes(scope.name));
        setScopes([...oldScopes, {name: status}]);
        setSelectedStatus(status);
      }
    }
  }

  useEffect(() => {
    getStatusCounts(setStatusCounts);
  }, [tableProps?.data]);

  const applyTagFilters = (tags) => {
    const filterObject = {
      filterField: "taggings.tag_id",
      operator: "in",
      filterCategory: "Tags"
    }
    handleFilterChange(filters, setFilters, tags, filterObject);
  }

  const applyDesignTypeFilters = (designType) => {
    const filterObject = {
      filterField: "mail_template_type",
      operator: "in",
      filterCategory: "Design Type"
    }
    handleFilterChange(filters, setFilters, designType, filterObject);
  }

  const filterInputs = [
    { title: "Tags",
      getOptions: (name = "") => taggingsApi.getOptions( {
        valueKey: "tag_id",
        labelKey: "tag.name",
        filters: [{ field: "taggable_type", operator: "=", value: "Design" }, {field: "tags.account_id", operator: "=", value: authApi.getCurrentAccountId()}],
        scopes: [{ name: "search_tag_by_name", args: name }]
      }),
      applyFilter: applyTagFilters,
      searchable: true
    },
    { title: "Design Type",
      getOptions: () => designTypes,
      applyFilter: applyDesignTypeFilters,
      searchable: false
    }
  ];

  return (
    <div>
      <div className="flex row flex-start">
        {statusCounts && designStatusConfig.map((status) => (
          designsByStatus(status, statusCounts, selectedStatus, onStatusClick)
        ))}
      </div>

      <hr className="hr margin-16-b margin-right" style={{marginTop: "-2px"}}/>

      <div className="full-width space-between vertical-align row" style={{margin: "0 20px 0px 0"}}>
        <div>
          {filterInputs && filterInputs.map(filterInput => (
            <DopeFilterDelegator filterInput={filterInput} filters={filters} key={filterInput.title}/>
          ))}
        </div>

        <InputGroup style={{width: "300px"}}>
          <Input onChange={value => handleSearchInput(value)} value={searchText}/>
          {searchText.length > 0 ? (<InputGroup.Addon onClick={handleSearchClear} style={{cursor: 'pointer'}}>
            <Icon as={MdOutlineClear}/>
          </InputGroup.Addon>) : (<InputGroup.Addon>
            <Icon as={MdOutlineSearch}/>
          </InputGroup.Addon>)}
        </InputGroup>

      </div>

      <div className="design-grid">
        {tableProps.data && tableProps.data.map((design, index) => {
          return (
            <DesignGridItem key={index} design={design} selectedStatus={selectedStatus}/>
          );
        })}
      </div>

      <div>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={['total', '-', 'limit', '|', 'pager', 'skip']}
          limitOptions={[10, 25, 50, 100]}
          locale={paginationLocale}
          {...paginationProps}
        />
      </div>
    </div>
  );
}

export default DesignGridPage;
