import { useDispatch, useSelector } from "react-redux";
import { createAsyncThunk, createSelector } from '@reduxjs/toolkit';

import createModelSlice from "../services/createModelSlice";
import { wrapAll } from "../utils/fn";
import DopeApi from '../services/DopeApi';

const api = new DopeApi('sweet_spot_analysis');

const previewAnalysis = createAsyncThunk(
  'sweet_spot_analysis/preview',
  async ({ id, ...params }, { rejectWithValue }) => {
    try {
      return await api.fetchMember(id, 'preview', { ...params });
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

const [sweetSpotAnalysisSlice, asyncActions] = createModelSlice('sweet_spot_analysis', {
  sort: (state, { payload: { sortColumn, sortDirection } }) => {
    state.current.isGetting = true;
    // Instead of querying the API again, we sort the data here as we already
    // have everything loaded.
    state.current.report_data = state.current.report_data.sort((a, b) => {
      if (sortDirection === 'asc') {
        return a[sortColumn] - b[sortColumn];
      }

      return b[sortColumn] - a[sortColumn];
    });

    state.current.isGetting = false;
  },
}, (builder) => {
  builder.addCase(previewAnalysis.pending, (state, action) => {
    state.current.isGetting = true;
    return state;
  });

  builder.addCase(previewAnalysis.fulfilled, (state, action) => {
    state.current.isGetting = false;
    state.current.report_data = action.payload.report_data;
    return state;
  })
});

export default sweetSpotAnalysisSlice.reducer;

export function useSweetSpotAnalysis() {
  const dispatch = useDispatch();
  const sweetSpotAnalysis = useSelector(state => state.sweetSpotAnalysis.current);
  const isSaving = useSelector(state => state.sweetSpotAnalysis.isSaving);

  const actions = wrapAll({
    ...sweetSpotAnalysisSlice.actions,
    ...asyncActions,
    preview: () => {
      return previewAnalysis({
        id: sweetSpotAnalysis.id,
        list_id: sweetSpotAnalysis.list_id,
        name: sweetSpotAnalysis.name,
      });
    },
    save: () => {
      const { name, list_id, ...rest } = sweetSpotAnalysis;
      return asyncActions.save({
        id: sweetSpotAnalysis.id,
        name,
        list_id,
      });
    },
  }, dispatch);

  return {
    sweetSpotAnalysis,
    isSaving,
    actions,
  };
}
