import React, { useEffect, useState } from "react";
import {useLocation} from "react-router-dom";

import StripeApi from "../stripe/StripeApi";
import { useAccount } from "./accountSlice";
import { useQuery2 } from "../hooks/useQuery";
import { unixTimestampToDateStr } from "../utils/date";
import { useStripeUI } from "../stripe/stripeUISlice";
import DopeTable from "../ui/DopeTable";
import DopeDrawer from "../ui/DopeDrawer";
import DopeQuickScopeButton from "../ui/DopeQuickScopeButton";
import InvoiceDrawer from "../stripe/InvoiceDrawer";
import StripeCardAdderWrapper from "../stripe/StripeCardAdderWrapper";
import StripeCardDrawer from "../stripe/StripeCardDrawer";
import DopeButton from "../ui/DopeButton";
import authApi from "../auth/authApi";

const stripeApi = new StripeApi;

const invoiceIDStyles = {
  fontSize: '12px',
  fontWeight: 400,
  color: '#255FDF'
};

const columns = [
  { type: 'clickable_text', label: 'Invoice', dataKey: 'number', customStyles: invoiceIDStyles },
  { type: 'date', label: 'Created Date', dataKey: 'created', dateConverter: unixTimestampToDateStr },
  { type: 'text', label: 'Invoice Type', dataKey: 'billing_reason' },
  { type: 'currency', label: 'Amount', dataKey: 'amount_due' },
  { type: 'pill', label: 'Status', dataKey: 'status', pillTypeKey: 'status' },
];

const AccountPaymentsTab = () => {
  const [unpaidScopeApplied, setUnpaidScopeApplied] = useState(false);
  const [cardReload, setCardReload] = useState(false);

  const [stripeUI, stripeUiActions] = useStripeUI();
  const [account, accountActions] = useAccount();
  const { tableProps, filters, setFilters, setScopes, scopes, rest, reload, loading } = useQuery2({
    api: stripeApi,
    initialPerPage: 10,
  });


  const location = useLocation();

  const subscriptionInvoicePaid = authApi.currentAccountSubscriptionIsPaid();

  useEffect(() => {
    if (location.state && location.state.unpaidInvoices) {
      applyUnpaidScope();
    }
  }, []);

  const onCellClick = (rowData) => {
    stripeUiActions.setDrawerInvoiceId(rowData.id);
  };

  const applyUnpaidScope = () => {
    const scopeApplied = !unpaidScopeApplied;
    if (scopeApplied) {
      setScopes([...scopes, {name: "unpaid_invoices"}, {name: "within_thirty_days"}]);
    } else {
      setScopes(scopes.filter(scope => scope.name !== "unpaid_invoices" && scope.name !== "within_thirty_days"));
    }
    setUnpaidScopeApplied(scopeApplied);
  };

  const onClose = () => {
    setCardReload(!cardReload);
    stripeUiActions.setCardDrawerOpen(false);
  };

  const nextPage = () => {
    reload({ starting_after: rest.next_cursor });
  };

  const previousPage = () => {
    reload({ ending_before: rest.previous_cursor });
  };

  const applyInvalidScopeButton = (<DopeQuickScopeButton onClick={() => applyUnpaidScope()} label="Unpaid Invoices" selected={unpaidScopeApplied} />);

  return (
    <>
      <DopeTable
        {...tableProps}
        columns={columns}
        filters={filters}
        setFilters={setFilters}
        onClickNumber={onCellClick}
        scopeButtons={applyInvalidScopeButton}
      />

      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
        <DopeButton
          disabled={!rest.has_previous || loading}
          props={{
            onClick: previousPage,
            label: "Previous Page",
            buttonClass: "text-link"
          }}
        />
        <DopeButton
          disabled={!rest.has_next || loading}
          props={{
            onClick: nextPage,
            label: "Next Page",
            buttonClass: "text-link"
          }}
        />
      </div>

      <DopeDrawer
        className="invoice-drawer"
        open={!!stripeUI.drawerInvoiceId}
        onClose={() => stripeUiActions.setDrawerInvoiceId(null)}
        bodyContent={
          <InvoiceDrawer
            id={stripeUI.drawerInvoiceId}
            account={account}
            onClose={() => stripeUiActions.setDrawerInvoiceId(null)}
          />
        }
      />

      <DopeDrawer
        className="payment-method-drawer"
        header={"Add Payment Method"}
        open={!!stripeUI.cardDrawerOpen}
        onClose={() => stripeUiActions.setCardDrawerOpen(false)}
        bodyContent={
          <StripeCardAdderWrapper
            Children={StripeCardDrawer}
            props={{
              onClose: onClose,
            }}
          />
        }
      />
    </>
  );
}

export default AccountPaymentsTab;
