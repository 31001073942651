import React from 'react';
import { Modal, Stack } from 'rsuite';

import DopeErrorMessages from './DopeErrorMessages';
import DopeButton from './DopeButton';

const iconWrapperStyle = {
  display: 'flex',
  justifyContent: 'flex-start',
  padding: '0.5rem',
  marginBottom: '1rem',
};

const iconStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '1rem',
  border: '2px solid #424242',
  borderRadius: '100%',
  fontSize: '2rem',
}

const DopeConfirmationModal = ({
    open = false,
    title = 'Confirm Action',
    message = 'Are you sure you want to proceed?',
    errors = null,
    loading = false,
    onConfirm = null,
    onCancel = null,
    cancelLabel = 'Cancel',
    confirmLabel = 'Confirm',
    icon = null,
    confirmButtonClass = 'filled',
    cancelButtonClass = 'outlined-red',
    size = 'md',
  }) => {

  return (
    <Modal open={open} onClose={onCancel} size={size || 'md'}>
      <Modal.Header>
        {icon && (
          <div style={iconWrapperStyle}>
           <div style={iconStyle}>{icon}</div>
          </div>
        )}
        <Modal.Title style={{fontWeight: 'bold'}}>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{color: '#8793A6'}}>{message}</div>
        {errors && <DopeErrorMessages errorMessages={errors} />}
      </Modal.Body>
      <Modal.Footer>
        <Stack justifyContent="flex-end" spacing="1rem">
          {onCancel && <Stack.Item flex={0} style={{width: 'auto'}}>
            <DopeButton
              props={{
                onClick: onCancel,
                buttonClass: cancelButtonClass,
                disabled: loading,
              }}
            >
              {cancelLabel}
            </DopeButton>
          </Stack.Item>}
          <Stack.Item style={{width: 'auto'}} >
            <DopeButton
              props={{
                onClick: onConfirm,
                buttonClass: confirmButtonClass,
                disabled: loading,
              }}
              loading={loading}
            >
              {confirmLabel}
            </DopeButton>
          </Stack.Item>
        </Stack>
      </Modal.Footer>
    </Modal>
  );
};

export default DopeConfirmationModal;
