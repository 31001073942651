import { useEffect, createRef, useState } from 'react';

import { Drawer } from "rsuite";
import { DopeFormGroup } from "../ui/DopeForm";
import QRCode from 'easyqrcodejs';
import DopeButton from "../ui/DopeButton";

import { checkUrl } from "../utils/validators";

const urlInput = {
  inputType: "url",
  name: "hot_lead_url",
  props: { label: "URL" },
};

const phoneInput = {
  inputType: "phone",
  name: "hot_lead_phone",
  props: { label: "Phone Number" },
};


const HotLeadDrawer = ({ open, onClose, index, dispatches, actions, onSave }) => {
  const [instance, setInstance] = useState(null);
  const [svg, setSVG] = useState(null);
  const [previousCode, setPreviousCode] = useState(null);
  const code = createRef();

  const dispatch = dispatches[index];
  const updateDispatch = actions.updateDispatchTemplate || actions.updateDispatch;

  useEffect(() => {
    if (dispatch?.hot_lead_url && dispatch.hot_lead_url !== previousCode) {
      if (instance) {
        instance.clear();
      }

      const qr = new QRCode(code.current, {
        text: dispatch.hot_lead_url,
        onRenderingEnd: (canvas, svg) => {
          setSVG(svg);
          setPreviousCode(dispatch.hot_lead_url);
        },
        drawer: 'svg',
        width: 150,
        height: 150,
      });
      setInstance(qr);
    }
  }, [dispatch?.hot_lead_url]);


  if (!dispatch) {
    return null;
  }

  const canSave = () => {
    if (typeof dispatch.hot_lead_url !== 'string' || dispatch.hot_lead_url.trim() === '') {
      return false;
    }

    if (typeof dispatch.hot_lead_phone !== 'string' || dispatch.hot_lead_phone.trim() === '') {
      return false;
    }

    const validPhone = dispatch.hot_lead_phone.length >= 10;

    const validUrl = checkUrl(dispatch.hot_lead_url);

    if (!validPhone || !validUrl) {
      return false;
    }

    return true;
  }

  const dispatchTemplateInputProps = {
    values: dispatch,
    onChange: (update) => {
      updateDispatch({ index, update });
    },
    errors: [],
  };

  const reset = () => {
    setPreviousCode(null);
    instance?.clear();
  };

  const close = (remove = false) => {
    if (!canSave()) {
      updateDispatch({ index, update: {
        hot_lead_url: null,
        hot_lead_phone: null,
      }});
    }

    reset();

    onClose(dispatch, remove);
  };

  return (
    <>
      <Drawer
        size="sm"
        placement="right"
        open={open}
        onClose={close}
        className='hot-lead-drawer'
      >
        <Drawer.Header className="hidden-break">
          <Drawer.Title><div style={{ fontWeight: 600, fontSize: "22px"}}>Where should the QR code on your Hot Leads go?</div></Drawer.Title>
        </Drawer.Header>
        <Drawer.Body className="custom-drawer-padding">
          <div>
            <DopeFormGroup input={urlInput} {...dispatchTemplateInputProps} />
          </div>
          <div className='margin-tb'>
            <DopeFormGroup input={phoneInput} {...dispatchTemplateInputProps} />
          </div>
          <p><b>Test QR Code</b></p>
          <p>Test your QR code below to make sure it brings your customers to the right place.</p>
          <div className='margin-tb hot-lead-code'>
            <div ref={code}></div>
          </div>
          <div className="hot-lead-drawer-actions">
              <DopeButton
                props={{
                  onClick: () => close(true),
                  buttonClass: "outlined-red",
                }}
              >
                Remove
              </DopeButton>

              <DopeButton
                props={{
                  onClick: () => {
                    const validUrl = checkUrl(dispatch.hot_lead_url);

                    updateDispatch({ index, update: { hot_lead_url: validUrl }});

                    onSave(dispatch);
                  },
                  buttonClass: "filled",
                }}
                disabled={!canSave()}
              >
                Setup Hot Lead
              </DopeButton>
          </div>
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default HotLeadDrawer;
