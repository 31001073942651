import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { useCampaign, useCampaignListGenerationSettings } from "./campaignSlice";

import CampaignAudienceSummary from "./CampaignAudienceSummary";
import CampaignDispatchList from "./CampaignDispatchList";
import CampaignPurchaseSummary from "./CampaignPurchaseSummary";
import CampaignDispatchWarning from "./CampaignDispatchWarning";
import DopePill from "../ui/DopePill";
import HotLeadDrawer from "./HotLeadDrawer";

const CampaignReview = () => {
  const [campaign, campaignActions] = useCampaign();
  const [settings] = useCampaignListGenerationSettings();
  const { scheduled } = campaign;
  const [hotLeadDrawerOpen, setHotLeadDrawerOpen] = useState(false);
  const [dispatchTemplate, setDispatchTemplate] = useState(null);

  const { pageTabs } = useOutletContext();

  const pageHeader = !scheduled && (
    <div className="page-header">
      <p className="header-text">Let's Review!</p>
      <p className="help-text">Review your campaign and make sure everything looks right.</p>
    </div>
  );

  return (
    <>
      <div className="page">
        {pageTabs}
        {pageHeader}

        <div className="campaign-name-header margin-16-b pad-t">
          <span>{campaign.name} - {campaign.is_eddm ? campaign.eddm.audience : settings.audience_type}</span> <DopePill text={campaign.effective_status} /> <span className="label large">{campaign.status_message}</span>
        </div>

        <CampaignAudienceSummary />

      </div>

      <div className="page">
        <div className="campaign-review-label large">Mailer Schedule</div>
        <CampaignDispatchList
          openDrawer={(index) => {
            setDispatchTemplate(index);
            setHotLeadDrawerOpen(true);
          }}
        />
      </div>

      <div className="page">
        <CampaignPurchaseSummary />
      </div>

      <CampaignDispatchWarning />

      <HotLeadDrawer
        open={hotLeadDrawerOpen}
        onClose={(dispatch) => {
          setHotLeadDrawerOpen(false);
          setDispatchTemplate(null);
        }}
        dispatches={campaign.dispatches}
        index={dispatchTemplate}
        onSave={(dispatch) => {
          setHotLeadDrawerOpen(false);
          setDispatchTemplate(null);
        }}
        actions={campaignActions}
      />
    </>
  );
};

export default CampaignReview;
