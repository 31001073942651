import { Link } from "react-router-dom";
import { SlGraph } from "react-icons/sl";
import { MdMailOutline } from "react-icons/md";

const styles = {
  cursor: "pointer",
  fontWeight: 600,
  fontSize: "13px",
  lineHeight: "20px",
  color: "#292B2E",
  marginBottom: "10px",
  display: "flex",
  alignItems: "center",
};

const lastItem = {
  ...styles,
  marginBottom: "0px",
};

const iconStyles = {
  marginRight: "7px",
  color: "#8793A6",
  fontSize: "18px",
};

const HotLeadDropdown = ({ rowData: currentHotLead, onClose }) => {
  let { id } = currentHotLead;

  return (
    <>
      <div>
        <Link to={`/hot_leads/${id}`} style={styles}><MdMailOutline style={iconStyles} />View Mailer</Link>
        <Link to={`/hot_leads/${id}/scans`} style={lastItem}><SlGraph style={iconStyles} />See Scan Data</Link>
      </div>
    </>
  )
}

export default HotLeadDropdown;
