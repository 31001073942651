import { configureStore } from '@reduxjs/toolkit';
import accountReducer from "./accounts/accountSlice";
import campaignReducer from './campaigns/campaignSlice';
import campaignUIReducer from './campaigns/campaignUISlice';
import contactReducer from './contacts/contactSlice';
import contactUISlice from './contacts/contactUISlice';
import dopeUISlice from "./ui/dopeUISlice";
import listReducer from './lists/listSlice';
import listUISlice from './lists/listUISlice';
import userSlice from "./users/userSlice";
import automationReducer from './automations/automationSlice';
import automationUIReducer from './automations/automationUISlice';
import stripeUISlice from "./stripe/stripeUISlice";
import mailPieceReducer from './mailPieces/mailPieceSlice';
import mailPieceUISlice from "./mailPieces/mailPieceUISlice";
import designReducer from './designs/designSlice';
import conversionReportReducer from './analysis/conversionReportSlice';
import mediaUploadSlice from './editor/mediaUploadSlice';
import qrCodeSlice from './editor/qrCodeSlice';
import designTemplateSlice from './designs/designTemplateSlice';
import designVersionSlice from './designs/designVersionSlice';
import trackingNumberSlice from './analysis/trackingNumberSlice';
import trackingNumberUISlice from './analysis/trackingNumberUISlice';
import listPullSlice from './list_pulls/listPullSlice';
import sweetSpotAnalysisSlice from './analysis/sweetSpotAnalysisSlice';
import sweetSpotAnalysisUISlice from './analysis/sweetSpotAnalysisUISlice';

export default configureStore({
  reducer: {
    account: accountReducer,
    campaign: campaignReducer,
    campaignUI: campaignUIReducer,
    contact: contactReducer,
    contactUI: contactUISlice,
    dopeUI: dopeUISlice,
    list: listReducer,
    listUI: listUISlice,
    user: userSlice,
    automation: automationReducer,
    automationUI: automationUIReducer,
    stripeUI: stripeUISlice,
    mailPiece: mailPieceReducer,
    mailPieceUI: mailPieceUISlice,
    design: designReducer,
    conversionReport: conversionReportReducer,
    mediaUpload: mediaUploadSlice,
    qrCode: qrCodeSlice,
    designTemplate: designTemplateSlice,
    designVersion: designVersionSlice,
    trackingNumber: trackingNumberSlice,
    trackingNumberUI: trackingNumberUISlice,
    listPull: listPullSlice,
    sweetSpotAnalysis: sweetSpotAnalysisSlice,
    sweetSpotAnalysisUI: sweetSpotAnalysisUISlice,
  },
});
